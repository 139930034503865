import React from "react";

function Loader() {
  return (
    <>
      <div className="loader-wrapper">
        <div className="loader"></div>
        <div className="loder-section left-section"></div>
        <div className="loder-section right-section"></div>
      </div>
    </>
  );
}

export default Loader;
